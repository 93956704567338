import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PURCHASE_SUBSCRIPTION = gql`
  mutation PurchaseSubscription($data: PurchaseSubscriptionInput) {
    purchaseSubscription(data: $data) {
      message
      transactionId
    }
  }
`;
