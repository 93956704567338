import { CrownFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { useSubscription } from '../../../context/SubscriptionProvider';

function UpgradeButton() {
  const { setOpenUpgradeModal, setFeatureText, isFree } = useSubscription();
  const navigate = useNavigate();

  return (
    <Button
      icon={isFree && <CrownFilled className="crown-icon" />}
      type="primary"
      className="upgrade-btn d-flex align-center"
      onClick={() => {
        setFeatureText((prev) => ({
          ...prev,
          title: '',
          subText: '',
        }));
        setOpenUpgradeModal(false);
        navigate(ROUTES.SUBSCRIPTION);
      }}
    >
      Upgrade Now
    </Button>
  );
}

export default UpgradeButton;
