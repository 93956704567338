import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Divider, Form, Row, Space, Typography } from 'antd';
import { get, isEmpty, map, omit, times } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  INSTRUCTION,
  MODE_OF_CONDUCT,
  QUESTION_ID,
  QUESTION_TYPE,
  ROUTES,
} from '../../../common/constants';
import { handleProtectedNavigation } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import RouterPrompt from '../../../components/RouterPrompt';
import { toastSuccess } from '../../../components/ToastContainer';
import { usePreventNavigation } from '../../../context/PreventNavigationProvider';
import { useSubscription } from '../../../context/SubscriptionProvider';
import useRouter from '../../../hooks/useRouter';
import { CREATE_QUIZ, UPDATE_QUIZ } from '../graphql/Mutations';
import { GET_SINGLE_QUIZ_BY_ID } from '../graphql/Queries';
import QuizDetailsForm from './QuizDetailsForm';
import QuizQuestionForm from './QuizQuestionForm';

function QuizForm({ edit }) {
  const [form] = Form.useForm();
  const watchForm = Form.useWatch(['questionData'], form);
  const { id } = useParams();
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [btnDisable, setBtnDisable] = useState(edit);
  const { navigate, params } = useRouter();
  const { setIsDirty } = usePreventNavigation();
  const { refetch, filter } = useSubscription();

  const [createQuiz, { loading: createQuizLoading }] = useMutation(
    CREATE_QUIZ,
    {
      onCompleted: () => {
        navigate(ROUTES.MAIN);
        toastSuccess('Quiz created successfully!');
        setBtnLoading(false);
        refetch({
          filter,
        });
      },
      onError() {},
    },
  );

  const { loading } = useQuery(GET_SINGLE_QUIZ_BY_ID, {
    skip: !edit,
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        quizId: id,
      },
    },
    onCompleted: (res) => {
      const filesData = {
        ...get(res, 'getQuizById.quiz', {}),
        questionInterval: parseFloat(
          get(res, 'getQuizById.quiz.intervalTime', '0'),
        ),
        questionData: map(
          get(res, 'getQuizById.quiz.questions', []),
          (question) => {
            const { correctAnswer, optionsValues } = question;

            const formattedOptions = map(optionsValues, (option, idx) => ({
              title: option?.title,
              correctAnswer:
                correctAnswer === get(QUESTION_ID, `[${idx}].value`, ''),
            }));
            return {
              ...question,
              optionsValues: times(
                4,
                (idx) =>
                  formattedOptions[idx] || { title: '', correctAnswer: false },
              ),
            };
          },
        ),
      };
      form.setFieldsValue(omit(filesData, 'questions'));
    },
    onError() {},
  });

  const [updateQuiz, { loading: updateQuizLoading }] = useMutation(
    UPDATE_QUIZ,
    {
      onCompleted: () => {
        navigate(ROUTES.MAIN);
        toastSuccess('Quiz updated successfully!');
        setBtnLoading(false);
        refetch({
          filter,
        });
      },
      onError() {},
    },
  );

  const onFinishFailed = ({ errorFields = [] }) => {
    if (!errorFields?.length) return;
    const errIndex = errorFields[errorFields?.length - 1]?.name?.[1];
    if (errIndex === undefined) return;
    // eslint-disable-next-line no-undef
    const errorElement = document.querySelector(`#question-${errIndex}`);
    if (errorElement) {
      errorElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  const handleFinish = async (values) => {
    setBtnLoading(true);
    if (!edit) {
      try {
        const res = createQuiz({
          variables: {
            data: {
              quizData: {
                title: values?.title,
                description: values?.description,
                instruction: values?.instruction,
                publishStatus: values?.publishStatus,
                quizInitialDelay: values?.quizInitialDelay,
                questionInterval: values?.questionInterval,
                marksPerQuestion: values?.marksPerQuestion,
                modeOfConduct: values?.modeOfConduct,
              },
              questionData:
                values?.questionData?.map((items) => ({
                  ...items,
                  correctAnswer:
                    QUESTION_ID[
                      items?.optionsValues?.findIndex(
                        (item) => item?.correctAnswer,
                      )
                    ]?.value || QUESTION_ID[0]?.value,
                  optionsValues:
                    items?.optionsValues
                      ?.filter((item) => !isEmpty(item?.title))
                      ?.map((options, i) => ({
                        title: options?.title,
                        id: QUESTION_ID[i]?.value,
                      })) || [],
                })) || [],
            },
          },
        });
        if (res) {
          setBtnLoading(false);
        }
      } catch (error) {
        //
      } finally {
        setBtnLoading(false);
      }
    } else {
      try {
        const newUpdate = {
          data: {
            updateQuizData: {
              title: values?.title,
              description: values?.description,
              instruction: values?.instruction,
              publishStatus: values?.publishStatus,
              quizInitialDelay: values?.quizInitialDelay,
              questionInterval: values?.questionInterval,
              marksPerQuestion: values?.marksPerQuestion,
              quizId: params?.id,
              modeOfConduct: values?.modeOfConduct,
            },
            updateQuestionData:
              values?.questionData?.map((item) => ({
                ...(item?.id && { questionId: item?.id }),
                type: item?.type,
                title: item?.title,
                correctAnswer:
                  QUESTION_ID[
                    item?.optionsValues?.findIndex((ans) => ans?.correctAnswer)
                  ]?.value || QUESTION_ID[0]?.value,
                optionsValues:
                  item?.optionsValues
                    ?.filter((items) => !isEmpty(items?.title))
                    ?.map((options, i) => ({
                      title: options?.title,
                      id: QUESTION_ID[i]?.value,
                    })) || [],
              })) || [],
          },
        };

        const res = updateQuiz({
          variables: newUpdate,
        });
        if (res) {
          setBtnLoading(false);
        }
      } catch (error) {
        //
      } finally {
        setBtnLoading(false);
      }
    }
  };

  const onValuesChanges = () => {
    setIsDirty(true);
    setShowPrompt(true);
    setBtnDisable(false);
  };

  const handleBack = () => {
    setIsDirty(true);
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, ROUTES.MAIN));
  };

  useEffect(() => {
    if (edit) {
      const disableTime = setTimeout(() => setBtnDisable(true), 500);
      return () => clearTimeout(disableTime);
    }
  }, [edit]);

  return (
    <LoaderComponent spinning={loading} setHeight={100}>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="d-flex justify-between">
              <Typography.Title level={4} className="m-0 head-title">
                Quiz Details
              </Typography.Title>
              <div>
                <Space size="small" wrap>
                  <Button
                    type="default"
                    onClick={handleBack}
                    loading={btnLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => form.submit()}
                    loading={loading || updateQuizLoading || createQuizLoading}
                    disabled={btnDisable}
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </div>
          </>
        }
      >
        <Form
          name="QuizForm"
          className="card-body-wrapper"
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChanges}
          initialValues={{
            instruction: INSTRUCTION,
            questionData: [
              {
                title: '',
                type: QUESTION_TYPE?.[0].value,
                optionsValues: [
                  { title: '', correctAnswer: false },
                  { title: '', correctAnswer: false },
                  { title: '', correctAnswer: false },
                  { title: '', correctAnswer: false },
                ],
              },
            ],
            quizInitialDelay: 5,
            questionInterval: 10,
            marksPerQuestion: 10,
            modeOfConduct: MODE_OF_CONDUCT.QUIZ.key,
          }}
        >
          <Row
            gutter={[16, 16]}
            className="quiz-form-wrapper"
            id="quiz-details-section"
          >
            <QuizDetailsForm />
            <Col span={24} id="quiz-questions-section">
              <Typography.Title level={4} className="m-0">
                Q&A Section
              </Typography.Title>
              <Divider className="m-0" />
            </Col>
            <QuizQuestionForm
              form={form}
              watchForm={watchForm}
              loading={loading}
            />
          </Row>
        </Form>
        <RouterPrompt
          isPrompt={isPrompt}
          handleOK={() => {
            setIsDirty(false);
            handleProtectedNavigation(true, navigate, -1);
          }}
          handleCancel={() => {
            setIsDirty(true);
            setIsPrompt(false);
          }}
        />
      </Card>
    </LoaderComponent>
  );
}

export default QuizForm;
