import { Modal } from 'antd';
import React from 'react';
import { useSubscription } from '../../../context/SubscriptionProvider';
import UpgradeButton from './UpgradeButton';

function FeatureRestrictionModal() {
  const {
    openUpgradeModal,
    setOpenUpgradeModal,
    featureText,
    setFeatureText,
  } = useSubscription();
  return (
    <Modal
      width={400}
      title={featureText?.title}
      open={openUpgradeModal}
      onCancel={() => {
        setFeatureText((prev) => ({
          ...prev,
          title: '',
          subText: '',
        }));
        setOpenUpgradeModal(false);
      }}
      footer={false}
    >
      <p className="mt-0">{featureText?.subText}</p>
      <UpgradeButton />
    </Modal>
  );
}

export default FeatureRestrictionModal;
