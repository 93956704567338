import { gql } from '@apollo/client';

export const GET_USER_SUBSCRIPTION = gql`
  query GetUserSubscription($data: GetUserSubscriptionInput) {
    getUserSubscription(data: $data) {
      userSubscription {
        id
        subscriptionPlanId
        userId
        subscriberId
        subscriptionStatus
        subscriptionAmount
        subscriptionRenewalDate
        subscriptionCancellationDate
        lastWebhookSyncDate
        paymentMethod
        customerId
        subscriptionFeatures
        isActive
      }
      subscriptionPlanName
    }
  }
`;

export const GET_TRANSACTIONS = gql`
  query GetTransactions($filter: TransactionFilter) {
    getTransactions(filter: $filter) {
      count
      transactions {
        id
        subscriptionPlanId
        userSubscriptionId
        userId
        amount
        statusHistory
        referenceData
        subscriptionStartDate
        subscriptionEndDate
        transactionId
        userSubscription {
          id
          subscriptionPlanId
          userId
          subscriberId
          subscriptionStatus
          subscriptionAmount
          subscriptionRenewalDate
          subscriptionCancellationDate
          lastWebhookSyncDate
          paymentMethod
          customerId
          subscriptionFeatures
          isActive
        }
        subscriptionPlan {
          planName
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      id
      email
      refreshToken
      isVerified
      isConfigSet
    }
  }
`;
