import { CheckCircleFilled, RightCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Divider, Modal, Result } from 'antd';
import React, { useMemo, useState } from 'react';
import { PLANS } from '../../../common/constants';
import { parseNumbers } from '../../../common/utils';
import { useSubscription } from '../../../context/SubscriptionProvider';
import { triggerPaddlePayment } from '../../../paddle';
import { PURCHASE_SUBSCRIPTION } from '../graphql/Mutations';

const { FREE, ENTERPRISE } = PLANS;

function PlanCard({ planName = '', title, amount = 0, info, plan = [] }) {
  const [openPay, setOpenPay] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const { currentPlan, setOpenLoader, setBtnLoadingOff } = useSubscription();
  const icon = <CheckCircleFilled className="plan-description-icon allowed" />;

  const features = useMemo(() => {
    if (!plan?.subscriptionFeatures) return {};
    const jsonParse = JSON.parse(plan?.subscriptionFeatures);

    return parseNumbers(jsonParse);
  }, [plan?.subscriptionFeatures]);

  const [purchaseSubscription] = useMutation(PURCHASE_SUBSCRIPTION, {
    onError() {
      setBtnLoading(false);
    },
  });

  const handleContactClick = () => {
    const email = 'support@qrizo.app';
    const subject = 'Enterprise inquiry for custom plan';
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    // eslint-disable-next-line no-undef
    window.open(mailtoUrl, '_blank');
  };

  const onPurchase = async () => {
    setBtnLoading(true);
    setBtnLoadingOff(() => () => setBtnLoading(false));
    purchaseSubscription({
      variables: {
        data: {
          subscriptionPlanId: plan?.id,
        },
      },
      context: {
        bypassSuccessMessage: true,
      },
      onCompleted: (data) => {
        const transactionId = data?.purchaseSubscription?.transactionId;

        triggerPaddlePayment(
          transactionId,
          () => {
            // resolved callback
            setIsSuccess(true);
            setOpenPay(true);
          },
          () => {
            // reject callback
            setIsSuccess(false);
            setOpenPay(true);
          },
        );
      },
    });
  };

  const onClose = () => {
    setOpenPay(false);
    if (isSuccess) {
      setOpenLoader(true);
    } else {
      setBtnLoading(false);
    }
  };

  return (
    <div className="plan-card">
      <Card hoverable>
        <h3 className="title">{title}</h3>
        {planName !== ENTERPRISE && (
          <h2 className="price">
            $
            {planName === FREE ? (
              '0'
            ) : (
              <span>
                {amount}
                <span className="sub-text">/month</span>
              </span>
            )}
          </h2>
        )}
        <p className="description">{info}</p>
        <Divider />
        <ul className="feature-list">
          <li>
            {icon}Allowed Participants: {features?.allowedParticipants}
          </li>

          {features?.unlimitedQuizzes ? (
            <li>{icon}Unlimited Quizzes</li>
          ) : (
            <li>
              {icon}Allowed Quizzes: {features?.allowedQuiz}
            </li>
          )}

          {features?.unlimitedQuestions && <li>{icon}Unlimited Questions</li>}

          {features?.unlimitedSessions ? (
            <li>{icon}Unlimited Sessions</li>
          ) : (
            <li>
              {icon}Allowed Sessions: {features?.allowedSession}
            </li>
          )}

          {features?.customQuizBranding && <li>{icon}Custom Quiz Branding</li>}

          {features?.quizMode === 'EXAM_QUIZ' && (
            <li>{icon}Allowed Exam Mode</li>
          )}

          {planName !== FREE && (
            <li className="d-flex flex-vertical justify-start align-start last-item">
              <span className="content">
                {icon}
                Advance Configuration
              </span>
              <ul>
                {features?.intervalTime && (
                  <li>
                    <RightCircleOutlined className="plan-description-icon font-small" />{' '}
                    Interval time (seconds)
                  </li>
                )}
                {features?.changePerQuestion && (
                  <li>
                    <RightCircleOutlined className="plan-description-icon font-small" />{' '}
                    Change marks (per question)
                  </li>
                )}
              </ul>
            </li>
          )}
        </ul>
        {currentPlan === FREE && planName !== FREE && planName !== ENTERPRISE && (
          <Button
            block
            className="mt-16"
            size="large"
            type="primary"
            loading={btnLoading}
            onClick={onPurchase}
          >
            Purchase Now
          </Button>
        )}
        {planName === ENTERPRISE && (
          <Button
            block
            type="primary"
            size="large"
            className="mt-8 mb-8 w-full"
            onClick={handleContactClick}
          >
            Contact us
          </Button>
        )}
      </Card>

      {/* Payment Modal */}
      <Modal open={openPay} footer={false} onCancel={onClose}>
        <Result
          status={isSuccess ? 'success' : 'error'}
          className="pt-24 pb-6"
          title={
            isSuccess
              ? 'Successfully purchased subscription!'
              : 'Subscription purchase failed!'
          }
          subTitle={
            isSuccess
              ? 'You can now access advanced features'
              : `There was an issue with your payment process.
              Please check your payment details and try again.`
          }
          extra={[
            <Button type="primary" key="console" size="large" onClick={onClose}>
              Close
            </Button>,
          ]}
        />
      </Modal>
    </div>
  );
}

export default PlanCard;
