import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSubscription } from '../../context/SubscriptionProvider';
import EditorToolbar, { formats, modules } from './QuillToolbar';

const QuillEditor = ({ value, ...rest }) => {
  const editorRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const { isFree } = useSubscription();
  // eslint-disable-next-line no-undef
  const url = window.location.href;
  const isAddOrEdit = url.includes('add') || url.includes('edit');

  useEffect(() => {
    if (editorRef?.current) {
      const checkIfEmpty =
        editorRef?.current?.getEditor()?.getText()?.trim()?.length === 0;
      setIsEmpty(checkIfEmpty);
    }
  }, [value]);

  return (
    <div
      className={`text-editor ${
        isFree && !isAddOrEdit ? 'text-editor-disabled' : ''
      }`}
    >
      <EditorToolbar />
      <ReactQuill
        ref={editorRef}
        theme="snow"
        value={value}
        placeholder="Write something awesome..."
        modules={modules}
        formats={formats}
        className={`editor ${isEmpty ? 'editor-empty' : ''}`}
        readOnly={isFree && !isAddOrEdit}
        {...rest}
      />
    </div>
  );
};

export default QuillEditor;
