import {
  DeleteFilled,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { QUESTION_ID, QUESTION_TYPE } from '../../../common/constants';
import RouterPrompt from '../../../components/RouterPrompt';

const { Text } = Typography;

const DeleteQuestionComponent = ({ remove }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <RouterPrompt
        isPrompt={open}
        title="Delete question"
        description="Are you sure you want to delete this question?"
        handleOK={() => {
          remove();
          setOpen(false);
        }}
        handleCancel={() => setOpen(false)}
      />
      <Space size="small" wrap>
        <Button
          type="primary"
          icon={<DeleteFilled />}
          onClick={() => setOpen(true)}
        />
      </Space>
    </>
  );
};

const QuizQuestionForm = ({
  form,
  loading,
  watchForm = [],
  addNewQuiz = true,
  isFormDisabled = false,
}) => {
  const [showError, setShowError] = useState([]);

  const isCorrectChecked = (index) => {
    const options = watchForm?.[index]?.optionsValues;
    const isChecked = options?.some((option) => option?.correctAnswer);
    return isChecked;
  };

  const validateOptions = (idx) => {
    const isChecked = isCorrectChecked(idx);
    const list = [...showError];
    list[idx] = !isChecked;
    setShowError(list);
    return isChecked ? Promise.resolve() : Promise.reject();
  };

  const showMoreInfo = (key) => {
    const title = watchForm?.[key]?.title;
    return (
      title?.length > 200 && (
        <span className="pointer ml-6">
          <Tooltip title={title} overlayClassName="option-tooltip-value">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      )
    );
  };

  useEffect(() => {
    if (loading || !watchForm?.length) return;
    setShowError((prev) => {
      const updatedArr = watchForm.map((_, i) => {
        const isChecked = isCorrectChecked(i);
        const newIndex = i === prev?.length;
        return newIndex ? false : !isChecked;
      });
      return updatedArr;
    });
  }, [watchForm?.length]);

  return (
    <>
      <Col span={24}>
        <Form.List name="questionData">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <div key={key} className="mb-16">
                  <Card
                    id={`question-${idx}`}
                    className={
                      showError?.[idx] &&
                      watchForm?.[idx]?.optionsValues
                        ?.slice(0, 2)
                        .every((op) => op?.title) &&
                      'border-error'
                    }
                    title={
                      <>
                        <div className="d-flex justify-between">
                          <h3 type="secondary" className="bold m-0">
                            Question {idx + 1}
                          </h3>
                          {!isFormDisabled && fields?.length > 1 && (
                            <DeleteQuestionComponent
                              remove={() => remove(name)}
                            />
                          )}
                        </div>
                      </>
                    }
                  >
                    <Row gutter={[16, 8]}>
                      <Col span={18}>
                        <div className="d-flex w-full align-center gap-6">
                          <Form.Item
                            className="flex-1"
                            {...restField}
                            label="Question Title"
                            name={[name, 'title']}
                            rules={[
                              { required: true, message: 'Title required!' },
                            ]}
                          >
                            <Input placeholder="Title" className="w-full" />
                          </Form.Item>
                          <span>{showMoreInfo(key)}</span>
                        </div>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          label="Question Type"
                          name={[name, 'type']}
                          rules={[
                            { required: true, message: 'Type required!' },
                          ]}
                        >
                          <Select placeholder="Select Question Type">
                            {map(QUESTION_TYPE, (list) => (
                              <Select.Option
                                key={list?.value}
                                value={list?.value}
                              >
                                {list?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24} className="options">
                        <Form.List name={[name, 'optionsValues']}>
                          {(innerFields) => (
                            <Row>
                              {innerFields.map(
                                (
                                  {
                                    key: keyInner,
                                    name: nameInner,
                                    ...restSubField
                                  },
                                  index,
                                ) => (
                                  <Col
                                    span={12}
                                    className="d-flex mb-24 align-center option-input-field"
                                    key={keyInner}
                                  >
                                    <Text>{QUESTION_ID[index]?.value}.</Text>
                                    <Form.Item
                                      {...restSubField}
                                      name={[nameInner, 'title']}
                                      rules={[
                                        {
                                          message: 'Option required!',
                                          required: [
                                            QUESTION_ID[0]?.value,
                                            QUESTION_ID[1]?.value,
                                          ].includes(QUESTION_ID[index]?.value),
                                        },
                                      ]}
                                      className="ml-8 m-0 option-filed"
                                    >
                                      <Input
                                        placeholder={`Option ${QUESTION_ID[index]?.value}`}
                                        onChange={(e) => {
                                          if (!e?.target?.value) {
                                            form.setFieldValue(
                                              [
                                                'questionData',
                                                name,
                                                'optionsValues',
                                                nameInner,
                                                'correctAnswer',
                                              ],
                                              false,
                                            );
                                          }
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restSubField}
                                      name={[nameInner, 'correctAnswer']}
                                      valuePropName="checked"
                                      className="ml-8 m-0"
                                      rules={[
                                        {
                                          validator: () => validateOptions(idx),
                                        },
                                      ]}
                                    >
                                      <Checkbox
                                        className={`${
                                          showError?.[idx] &&
                                          !!watchForm?.[idx]?.optionsValues?.[
                                            index
                                          ]?.title
                                            ? 'border-red'
                                            : ''
                                        }`}
                                        onChange={(e) => {
                                          if (e?.target?.checked) {
                                            [...Array(4).keys()].forEach(
                                              (element) => {
                                                if (element !== nameInner)
                                                  form.setFieldValue(
                                                    [
                                                      'questionData',
                                                      name,
                                                      'optionsValues',
                                                      element,
                                                      'correctAnswer',
                                                    ],
                                                    false,
                                                  );
                                              },
                                            );
                                          }
                                        }}
                                        disabled={
                                          isFormDisabled ||
                                          !watchForm?.[idx]?.optionsValues?.[
                                            index
                                          ]?.title
                                        }
                                      />
                                    </Form.Item>
                                    {watchForm?.[idx]?.optionsValues?.[index]
                                      ?.title?.length > 70 && (
                                      <span className="pointer ml-6">
                                        <Tooltip
                                          overlayClassName="option-tooltip-value"
                                          title={
                                            watchForm?.[idx]?.optionsValues?.[
                                              index
                                            ]?.title
                                          }
                                        >
                                          <InfoCircleOutlined />
                                        </Tooltip>
                                      </span>
                                    )}
                                  </Col>
                                ),
                              )}
                            </Row>
                          )}
                        </Form.List>
                      </Col>
                    </Row>
                  </Card>
                  <div>
                    {showError?.[idx] &&
                      watchForm?.[idx]?.optionsValues
                        ?.slice(0, 2)
                        .every((op) => op?.title) && (
                        <Text type="danger">
                          You need to select the correct answer!
                        </Text>
                      )}
                  </div>
                </div>
              ))}
              {addNewQuiz && (
                <Form.Item>
                  <Button
                    block
                    icon={<PlusOutlined />}
                    onClick={() =>
                      add({
                        title: '',
                        type: QUESTION_TYPE?.[0]?.value,
                        optionsValues: Array.from({ length: 4 }, () => ({
                          title: '',
                          correctAnswer: false,
                        })),
                      })
                    }
                  >
                    Add New Question
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      </Col>
    </>
  );
};

export default QuizQuestionForm;
