import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import '../../../styles/custom.less';
import { DELETE_ACCOUNT } from '../graphql/Mutations';

const DELETE_STAGE = {
  DELETE_CONFIRMATION: 'DELETE_CONFIRMATION',
  DELETE_REASON: 'DELETE_REASON',
  FINAL_MESSAGE: 'FINAL_MESSAGE',
};

const { DELETE_CONFIRMATION, DELETE_REASON, FINAL_MESSAGE } = DELETE_STAGE;

export default function DeleteAccount() {
  const [deleteStage, setDeleteStage] = useState(null);
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const otherReason = 'Other: Please specify';

  const options = [
    'Found a better alternative',
    'App is too complicated',
    'Privacy or security concerns',
    'Customer support issues',
  ];

  const [deleteAccount] = useMutation(DELETE_ACCOUNT, {
    onError() {},
  });

  const onOk = () => {
    if (deleteStage === DELETE_CONFIRMATION) {
      setDeleteStage(DELETE_REASON);
    } else if (deleteStage === DELETE_REASON) {
      setDeleteStage(FINAL_MESSAGE);
    }
  };

  const clearForm = () => {
    form.resetFields();
    setIsOtherChecked(false);
    setSelectedReasons([]);
  };

  const onCancel = () => {
    clearForm();
    setDeleteStage(null);
    if (deleteStage === FINAL_MESSAGE) {
      navigate(ROUTES.LOGOUT);
    }
  };

  const onCheckboxChange = (checkedValues) => {
    setSelectedReasons(checkedValues);
    setIsOtherChecked(checkedValues.includes(otherReason));
  };

  const onFinish = (values) => {
    const reasons = selectedReasons.filter((reason) => reason !== otherReason);

    const finalReasons =
      isOtherChecked && values?.otherReason
        ? [...reasons, values?.otherReason.trim()]
        : [...reasons];

    deleteAccount({
      variables: {
        data: {
          accountDeletionReasons: finalReasons,
        },
      },
      onCompleted: () => {
        setDeleteStage(FINAL_MESSAGE);
        // eslint-disable-next-line no-undef
        localStorage.clear();
      },
    });
  };

  return (
    <>
      <Button
        danger
        type="link"
        onClick={() => setDeleteStage(DELETE_CONFIRMATION)}
        disabled={false}
      >
        Delete Account
      </Button>

      <Modal
        title={
          // eslint-disable-next-line no-nested-ternary
          deleteStage === DELETE_CONFIRMATION
            ? 'Are you sure you want to delete your account?'
            : deleteStage === DELETE_REASON
            ? 'Why do you want to delete your account?'
            : 'Your account is deleted'
        }
        open={deleteStage !== null}
        onOk={onOk}
        onCancel={onCancel}
        okText={deleteStage === DELETE_CONFIRMATION ? 'Confirm' : null}
        footer={
          // eslint-disable-next-line no-nested-ternary
          deleteStage === DELETE_REASON ? (
            <>
              <Button
                onClick={() => {
                  clearForm();
                  setDeleteStage(DELETE_CONFIRMATION);
                }}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={() => form.submit()}>
                Delete Account
              </Button>
            </>
          ) : deleteStage === FINAL_MESSAGE ? null : undefined
        }
      >
        {deleteStage === DELETE_CONFIRMATION && (
          <Typography.Paragraph>
            Deleting your account will permanently erase all of your data and
            cancel any active subscriptions. This action is irreversible, and no
            refunds will be issued.
          </Typography.Paragraph>
        )}

        {deleteStage === DELETE_REASON && (
          <Form form={form} onFinish={onFinish} disabled={false}>
            <Row className="delete-reason">
              <Col span={12}>
                <Form.Item name="reasons">
                  <Checkbox.Group
                    options={[...options, otherReason]}
                    value={selectedReasons}
                    onChange={onCheckboxChange}
                  />
                </Form.Item>
              </Col>
              {isOtherChecked && (
                <Col span={24}>
                  <Form.Item name="otherReason" className="other-reason">
                    <Input.TextArea placeholder="I am deleting account because..." />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        )}

        {deleteStage === FINAL_MESSAGE && (
          <Typography.Paragraph>
            All your data has been permanently erased, and any active
            subscriptions have been canceled. Thank you for using our service.
          </Typography.Paragraph>
        )}
      </Modal>
    </>
  );
}
