import { Layout } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import brandLogo from '../assets/images/logo.png';
import { ROUTES } from '../common/constants';
import MobileLogoComponent from '../components/MobileLogoComponent';
import { usePreventNavigation } from '../context/PreventNavigationProvider';
import { useSubscription } from '../context/SubscriptionProvider';
import useRouter from '../hooks/useRouter';
import FeatureRestrictionModal from '../modules/plans/components/FeatureRestrictionModal';
import UpgradeButton from '../modules/plans/components/UpgradeButton';
import './App.css';
import AppHeader from './components/header/AppHeader';
import UserProfile from './components/header/UserProfile';

const { Content, Footer } = Layout;

const App = () => {
  const location = useLocation();
  const { state } = useContext(AppContext);
  const { isFree } = useSubscription();
  const { isDirty, changesLostWarning } = usePreventNavigation();
  const { navigate } = useRouter();

  const handleLogoLink = (e) => {
    if (!isDirty) return;
    e?.preventDefault();
    const popConfirm = changesLostWarning();
    if (popConfirm) {
      navigate(ROUTES.MAIN);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if ('serviceWorker' in navigator) {
      // eslint-disable-next-line no-undef
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }, [location?.pathname]);

  return (
    <>
      <FeatureRestrictionModal />
      <Layout className="site-layout">
        <AppHeader>
          <div className="header-wrapper">
            <Link id="logo" to={ROUTES?.MAIN} onClick={handleLogoLink}>
              <MobileLogoComponent
                className="mr-0"
                src={isFree ? brandLogo : state?.currentUser?.companyLogo}
              />
            </Link>
            <div className="d-flex align-center gap-16">
              {isFree && <UpgradeButton />}
              <UserProfile />
            </div>
          </div>
        </AppHeader>
        <div className="content-wrapper">
          <Content className="wrapper">
            <Outlet />
          </Content>
          <Footer className="app-footer d-flex justify-between item-center">
            <div className="align-center">
              Logicwind © {new Date().getFullYear()}
            </div>
            <div className="d-flex align-center">
              <div className="footer-mete-text mr-6">Crafted Mindfully at</div>
              <a
                href="/"
                aria-current="page"
                className="w-inline-block w--current d-flex"
              >
                <img
                  src="https://cdn.prod.website-files.com/6343ba64da79c936f0ca3e3e/64a2aa65eea345f87da8a207_logicwind.png"
                  loading="lazy"
                  width="120"
                  alt=""
                  className="lotous-ico-n"
                />
              </a>
            </div>
          </Footer>
        </div>
      </Layout>
    </>
  );
};

export default App;
