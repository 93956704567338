import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useRouter from '../hooks/useRouter';

const PreventNavigationContext = createContext();

export default function PreventNavigationProvider({ children }) {
  const [isDirty, setIsDirty] = useState(false);
  const { location, navigate } = useRouter();
  const {
    confirm,
    addEventListener,
    removeEventListener,
    // eslint-disable-next-line no-undef
  } = window;

  const updateIsDirty = (val) => {
    setIsDirty(val);
  };

  const changesLostWarning = useCallback(() => {
    if (!isDirty) return;
    // eslint-disable-next-line no-alert
    const leavePage = confirm(
      'If you leave this page all your changes would be lost!',
    );
    if (leavePage) {
      updateIsDirty(false);
    } else {
      navigate(location?.pathname);
    }
    return leavePage;
  }, [isDirty, location?.pathname]);

  // eslint-disable-next-line no-undef
  window.onpopstate = changesLostWarning;

  useEffect(() => {
    updateIsDirty(false);
  }, [location?.pathname]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e?.preventDefault();
        // eslint-disable-next-line no-param-reassign
        e.returnValue = '';
      }
    };
    addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const value = useMemo(() => ({ isDirty, setIsDirty, changesLostWarning }), [
    isDirty,
    setIsDirty,
    changesLostWarning,
  ]);

  return (
    <PreventNavigationContext.Provider value={value}>
      {children}
    </PreventNavigationContext.Provider>
  );
}

export const usePreventNavigation = () => {
  const ctx = useContext(PreventNavigationContext);
  if (!ctx)
    throw new Error(
      'usePreventNavigation must be used inside PreventNavigationProvider',
    );
  return ctx;
};
