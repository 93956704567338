import {
  DollarOutlined,
  DownOutlined,
  LoginOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Modal, Space } from 'antd';
import { capitalize } from 'lodash';
import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { usePreventNavigation } from '../../../context/PreventNavigationProvider';
import useRouter from '../../../hooks/useRouter';

const HandleLogout = ({ onLogoutConfirm }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <span className="display-block" onClick={() => setOpen(true)}>
        Logout
      </span>
      <Modal
        open={open}
        title={<p className="m-0">Are you sure you want to logout?</p>}
        onCancel={() => setOpen(false)}
        okText="Confirm"
        onOk={onLogoutConfirm}
      />
    </>
  );
};

const UserProfile = () => {
  const { pathname } = useLocation();
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const { isDirty, changesLostWarning } = usePreventNavigation();
  const userData = getCurrentUser();
  const { navigate } = useRouter();
  const derivedPath = pathname?.split('/')?.[1];

  const onLogoutConfirm = () => {
    if (userData?.isConfigSet) {
      navigate(ROUTES?.LOGOUT);
    } else {
      dispatch({ type: 'LOGOUT' });
      // eslint-disable-next-line no-undef
      window.location = ROUTES.LOGIN;
    }
  };

  const handleMenuItem = (e) => {
    if (isDirty && e?.key === derivedPath) return;
    changesLostWarning();
  };

  const items = [
    {
      key: 'my-account',
      label: <Link to={ROUTES?.ONBOARDING}>My Account</Link>,
      icon: <UserOutlined />,
    },
    {
      key: 'subscription',
      label: <Link to={ROUTES?.SUBSCRIPTION}>Subscription</Link>,
      icon: <DollarOutlined />,
    },
    {
      key: 'logout',
      label: isDirty ? (
        <span className="display-block" onClick={onLogoutConfirm}>
          Logout
        </span>
      ) : (
        <HandleLogout onLogoutConfirm={onLogoutConfirm} />
      ),
      icon: <LoginOutlined />,
    },
  ];

  const avatarText = userData?.companyName?.[0] || userData?.email?.[0];
  const menu = {
    items,
    onClick: handleMenuItem,
    selectedKeys: [`${derivedPath}`],
  };

  return (
    <Dropdown
      menu={menu}
      trigger={['click']}
      // eslint-disable-next-line no-undef
      getPopupContainer={() => document?.getElementById('header')}
    >
      <Space className="pointer d-flex align-center gap-4" size="small">
        {avatarText && (
          <Avatar className="header-avatar" alt="Avatar">
            {capitalize(avatarText)}
          </Avatar>
        )}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserProfile;
