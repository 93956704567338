import {
  CheckOutlined,
  CopyOutlined,
  CrownFilled,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  FloatButton,
  Popconfirm,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import { ORDER, ROUTES, STATUS_LIST } from '../../common/constants';
import EmptyStatePage from '../../components/EmptyStatePage';
import LoaderComponent from '../../components/LoaderComponent';
import SearchComponent from '../../components/SearchComponent';
import { useSubscription } from '../../context/SubscriptionProvider';
import useRouter from '../../hooks/useRouter';
import withFeatureRestriction from '../plans/components/withFeatureRestriction';
import { CREATE_SESSION, DELETE_QUIZ } from './graphql/Mutations';

const { Meta } = Card;
const { Text } = Typography;

const AddQuizButton = ({ onClick }) => {
  const { isFree, config, totalQuiz, setFeatureText } = useSubscription();
  const { navigate } = useRouter();
  const quizLimitExceeded = totalQuiz >= Number(config?.totalQuiz) + 1; // default quiz excluded
  const handleOnClick = () => {
    if (isFree && quizLimitExceeded) {
      setFeatureText((prev) => ({
        ...prev,
        title: 'Oops! You’ve Hit the Limit',
        subText:
          'You’ve created 3 quizzes already, which is the limit for the free plan. Want to keep going? Upgrade your plan and keep the creativity flowing!',
      }));
      onClick();
      return;
    }

    navigate(ROUTES.ADD_QUIZ);
  };

  return (
    <Button type="primary" onClick={handleOnClick}>
      {quizLimitExceeded && isFree && <CrownFilled className="crown-icon" />}
      Add Quiz
    </Button>
  );
};

const CheckRestrictionOnAddQuiz = withFeatureRestriction(AddQuizButton);

function NewQuizz() {
  const {
    config,
    isFree,
    setOpenUpgradeModal,
    setFeatureText,
    refetch,
    quizList,
    loadingQuizList,
    setFilter,
    filter,
  } = useSubscription();
  const { navigate } = useRouter();

  const [createSession] = useMutation(CREATE_SESSION, { onError() {} });

  const [deleteQuiz] = useMutation(DELETE_QUIZ, {
    onCompleted() {
      refetch({
        filter,
      });
    },
    onError() {},
  });
  const handleHostLink = async (quiz, action = '') => {
    if (isFree && quiz?.totalNoSession >= Number(config?.totalSession)) {
      setFeatureText((prev) => ({
        ...prev,
        title: 'Session Limit Exceeded',
        subText:
          'Free plan users can host up to 5 sessions per quiz. Upgrade now to enjoy unlimited sessions and enhanced hosting capabilities!',
      }));
      setOpenUpgradeModal(true);
      return;
    }
    const { data: session, errors } = await createSession({
      variables: {
        data: {
          quizId: quiz?.id,
        },
      },
      onCompleted() {
        refetch({
          filter,
        });
      },
    });
    if (!errors && session?.createSession?.sessions?.slug) {
      const sessionUrl = `${process.env.REACT_APP_TV_APP_URL}${session?.createSession?.sessions?.slug}`;
      if (action === 'copy') {
        return Promise.resolve(sessionUrl);
      }
      // eslint-disable-next-line no-undef
      window.open(sessionUrl, '_blank');
    }
  };

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      search: '',
      sortBy: ORDER[0]?.value,
    }));
  }, []);

  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="d-flex justify-between">
              <div>
                <Space size="small" wrap>
                  <Text type="secondary">Sort By</Text>
                  <SearchComponent
                    getData={(text) => {
                      setFilter((prev) => ({
                        ...prev,
                        search: text,
                      }));
                    }}
                  />
                  {!isFree && (
                    <Select
                      className="order-by-select-input"
                      placeholder="Select list order"
                      defaultValue={ORDER[0]?.value}
                      options={ORDER}
                      onChange={(value) =>
                        setFilter((prev) => ({
                          ...prev,
                          sortBy: value,
                        }))
                      }
                    />
                  )}
                </Space>
              </div>
              <CheckRestrictionOnAddQuiz />
            </div>
          </>
        }
      >
        {loadingQuizList && <LoaderComponent />}

        <FloatButton.BackTop
          // eslint-disable-next-line no-undef
          target={() => document.querySelector('.content-wrapper')}
          visibilityHeight={100}
        />
        <div className="card-body-wrapper">
          {!loadingQuizList && quizList?.getQuizzes?.quizzes?.length === 0 && (
            <EmptyStatePage />
          )}
          <Row gutter={[16, 16]}>
            {map(quizList?.getQuizzes?.quizzes, (data) => (
              <Col
                key={data?.id}
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={6}
                className="actions"
              >
                <Card
                  className="quiz-card full-width"
                  actions={[
                    isFree &&
                    data?.totalNoSession >= Number(config?.totalSession) ? (
                      <Button type="text" onClick={() => handleHostLink(data)}>
                        <CrownFilled className="crown-icon " />
                        <span className="link-text">Link</span>
                        <span key="copying" className="copy-link">
                          <CopyOutlined
                            key="copy-icon"
                            style={{ color: 'red' }}
                          />
                        </span>
                      </Button>
                    ) : (
                      <Typography.Paragraph
                        onClick={() => handleHostLink(data)}
                        key="copy-link"
                        className="copy-text"
                        copyable={{
                          icon: [
                            <span key="copying" className="copy-link">
                              <CopyOutlined key="copy-icon" color="#ffffff" />
                            </span>,
                            <span key="copied">
                              <CheckOutlined
                                key="copied-icon"
                                className="copy-icon"
                              />
                            </span>,
                          ],
                          tooltips: 'Copy quiz link',
                          text: () => handleHostLink(data, 'copy'),
                        }}
                      >
                        <Tooltip
                          title="Redirecting to quiz screen"
                          className="link"
                        >
                          <p className="link-text">Link</p>
                        </Tooltip>
                      </Typography.Paragraph>
                    ),
                    <Tooltip key="detail" title="View" className="link">
                      <Button
                        type="text"
                        onClick={() => {
                          navigate(`/details/${data?.id}`);
                        }}
                      >
                        <EyeOutlined />
                      </Button>
                    </Tooltip>,
                    <Tooltip key="edit" title="Edit" className="link">
                      <Button
                        type="text"
                        onClick={() => {
                          navigate(`/edit/${data?.id}`);
                        }}
                      >
                        <EditOutlined />
                      </Button>
                    </Tooltip>,
                    <Tooltip key="delete" title="Delete" className="link">
                      <Popconfirm
                        title="Delete Quiz"
                        description="Are you sure you want to delete this quiz?"
                        okText="Confirm"
                        onConfirm={() =>
                          deleteQuiz({
                            variables: { data: { quizId: data?.id } },
                          })
                        }
                      >
                        <Button type="text">
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </Tooltip>,
                  ]}
                >
                  <Meta
                    title={
                      <div className="card-title d-flex justify-between">
                        <p className="m-0 quiz-title">{data?.title}</p>
                        <Tag
                          className="d-flex align-center"
                          color={
                            data?.publishStatus === STATUS_LIST.PUBLISHED.value
                              ? 'success'
                              : 'error'
                          }
                        >
                          {STATUS_LIST?.[data?.publishStatus]?.name}
                        </Tag>
                      </div>
                    }
                    description={
                      <Typography.Paragraph ellipsis={{ rows: 2 }}>
                        {data?.description}
                      </Typography.Paragraph>
                    }
                  />
                  <div className="d-flex justify-around text-center">
                    <div>
                      <p className="mb-0">Questions</p>
                      <p className="mb-0">{data?.totalNoQuestion}</p>
                    </div>
                    <div>
                      <p className="mb-0">Sessions</p>
                      <p className="mb-0">{data?.totalNoSession}</p>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Card>
    </>
  );
}

export default NewQuizz;
