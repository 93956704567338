import { Modal } from 'antd';
import React, { useEffect } from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import { useSubscription } from '../../../context/SubscriptionProvider';

export default function PlanLoader() {
  const {
    openLoader,
    setOpenLoader,
    refetchSubscription,
    refetchData,
    btnLoadingOff,
  } = useSubscription();

  useEffect(() => {
    if (openLoader) {
      const timeout = setTimeout(() => {
        refetchSubscription();
        if (refetchData) {
          refetchData();
        }
        setOpenLoader(false);
        if (btnLoadingOff) {
          btnLoadingOff();
        }
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [openLoader]);

  return (
    <Modal
      open={openLoader}
      footer={false}
      closable={false}
      width={370}
      className="plan-loader"
    >
      <p className="m-0 text-center loader-text">
        Please wait <br /> while we update your plan...
      </p>
      <LoaderComponent setHeight={20} />
    </Modal>
  );
}
