import React from 'react';
import { useSubscription } from '../../../context/SubscriptionProvider';

const withFeatureRestriction = (Component) => () => {
  const { setOpenUpgradeModal, isFree } = useSubscription();

  const onClick = () => {
    if (isFree) {
      setOpenUpgradeModal(true);
      return;
    }
    setOpenUpgradeModal(false);
  };

  return <Component onClick={onClick} />;
};

export default withFeatureRestriction;
