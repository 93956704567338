import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_SUBSCRIPTION_PLANS = gql`
  query GetSubscriptionPlans {
    getSubscriptionPlans {
      count
      subscriptionPlans {
        id
        planType
        planName
        description
        amount
        currency
        priceId
        isActive
        recurring
        productId
        subscriptionFeatures
      }
    }
  }
`;
