import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Statistic,
  Tooltip,
  Typography,
} from 'antd';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import {
  GUTTER_VARIATIONS,
  LIMIT,
  PRIMARY_COLOR,
  ROUTES,
} from '../../common/constants';
import { formatDate } from '../../common/utils';
import TableComponent from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import { useSubscription } from '../../context/SubscriptionProvider';
import useRouter from '../../hooks/useRouter';
import PlanList from '../plans/PlanList';
import '../plans/plans.less';
import PlanLoader from './components/PlanLoader';
import { CANCEL_SUBSCRIPTION } from './graphql/Mutations';
import { GET_TRANSACTIONS } from './graphql/Queries';

const { Title } = Typography;

export default function Subscription() {
  const [openCancel, setOpenCancel] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { navigate } = useRouter();
  const {
    currentPlan,
    getUserSubscription,
    isFree,
    setOpenLoader,
    setRefetchData,
    setBtnLoadingOff,
  } = useSubscription();

  const { userSubscription: userSub } =
    getUserSubscription?.getUserSubscription || {};

  const statisticResponsive = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
    xxl: 8,
  };

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION, {
    onError() {
      setBtnLoading(false);
    },
  });

  const {
    data: transactions,
    loading: transactionLoading,
    refetch: transactionRefetch,
  } = useQuery(GET_TRANSACTIONS, {
    variables: {
      filter: {
        limit: LIMIT,
        skip: 0,
      },
    },
    onCompleted: () => {
      setRefetchData(() => transactionRefetch);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const cancelledPlanInfo = `Your plan has been cancelled on ${formatDate(
    userSub?.subscriptionCancellationDate,
    'DD-MM-YYYY',
  )}, but you still have access to current features until the expiration date: ${formatDate(
    userSub?.subscriptionRenewalDate,
    'DD-MM-YYYY',
  )}.`;

  const cancelPlan = () => {
    const { subscriberId } = userSub || {};
    if (!subscriberId) return;
    setBtnLoading(true);
    setBtnLoadingOff(() => () => setBtnLoading(false));
    cancelSubscription({
      variables: {
        data: {
          subscriberId,
        },
      },
      context: {
        bypassSuccessMessage: true,
      },
      onCompleted() {
        setOpenLoader(true);
      },
    });
    setOpenCancel(false);
  };

  const transactionColumns = [
    {
      title: 'Purchase Date',
      dataIndex: 'subscriptionStartDate',
      key: 'subscriptionStartDate',
      render: (value) => formatDate(value, 'DD-MM-YYYY'),
    },
    {
      title: 'Plan',
      dataIndex: 'subscriptionPlan',
      key: 'subscriptionPlan',
      render: (value) => capitalize(value?.planName) || '-',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => `$${value.toFixed(2)}` || '-',
    },
    {
      title: 'Status',
      dataIndex: 'statusHistory',
      key: 'statusHistory',
    },
    {
      title: 'End Date',
      dataIndex: 'subscriptionEndDate',
      key: 'subscriptionEndDate',
      render: (value) => formatDate(value, 'DD-MM-YYYY') || '-',
    },
  ];

  return (
    <>
      <PlanLoader />
      <LoaderComponent spinning={transactionLoading}>
        <Card
          className="ant-body-scroll"
          title={
            <div className="d-flex justify-between">
              <Title level={4} className="m-0 head-title">
                Subscription Details
              </Title>
              <Space size="small" wrap>
                <Button type="primary" onClick={() => navigate(ROUTES?.MAIN)}>
                  Back
                </Button>
              </Space>
            </div>
          }
        >
          <div className="card-body-wrapper d-flex d-flex-col gap-24">
            <Row
              gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
              className="user-subscription"
            >
              <Col span={8} {...statisticResponsive}>
                <Card>
                  <Statistic
                    title={
                      isFree ? (
                        'Plan'
                      ) : (
                        <span className="d-flex justify-between">
                          <span>Plan</span>
                          {!userSub?.subscriptionCancellationDate ? (
                            <Button
                              danger
                              size="small"
                              loading={btnLoading}
                              onClick={() => setOpenCancel(true)}
                            >
                              <span className="cancel-text">Cancel Plan</span>
                            </Button>
                          ) : (
                            <div>
                              <span className="mr-4 cancel-text">
                                Cancelled
                              </span>
                              <Tooltip title={cancelledPlanInfo}>
                                <InfoCircleOutlined className="cancel-icon" />
                              </Tooltip>
                            </div>
                          )}
                        </span>
                      )
                    }
                    value={capitalize(currentPlan) || '-'}
                    valueStyle={{ color: PRIMARY_COLOR }}
                  />
                </Card>
              </Col>
              <Col span={8} {...statisticResponsive}>
                <Card>
                  <Statistic
                    title="Amount"
                    value={userSub?.subscriptionAmount.toFixed(2)}
                    valueStyle={{ color: PRIMARY_COLOR }}
                    prefix="$"
                  />
                </Card>
              </Col>
              <Col span={8} {...statisticResponsive}>
                <Card>
                  <Statistic
                    title="Due Date"
                    value={
                      formatDate(
                        userSub?.subscriptionRenewalDate,
                        'DD-MM-YYYY',
                      ) || '-'
                    }
                    valueStyle={{ color: PRIMARY_COLOR }}
                  />
                </Card>
              </Col>
            </Row>
            {transactions?.getTransactions?.transactions?.length > 0 && (
              <TableComponent
                rowKey="id"
                loading={transactionLoading}
                columns={transactionColumns}
                dataSource={transactions?.getTransactions?.transactions}
              />
            )}
            <Divider className="divider">
              <h1 className="m-0 text-center">Plans and Pricing</h1>
            </Divider>
            <PlanList />
          </div>
        </Card>
      </LoaderComponent>

      {/* Cancel Plan Modal */}
      <Modal
        open={openCancel}
        onCancel={() => setOpenCancel(false)}
        title={
          <span className="d-flex gap-6">
            <ExclamationCircleFilled className="cancel-plan " />
            <span>Are you sure you want to cancel your current plan?</span>
          </span>
        }
        onOk={cancelPlan}
        okText="Yes"
        cancelText="No"
      >
        <p className="m-0">
          Canceling your plan will stop future renewals, but you'll retain
          access to current features until the expiration date.
        </p>
      </Modal>
    </>
  );
}
