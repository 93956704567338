import { gql } from '@apollo/client';

export const UPDATE_CONFIG = gql`
  mutation UpdateConfig($data: UpdateConfigInputData!) {
    updateConfig(data: $data) {
      message
      configs {
        id
        companyLogo
        companyName
        primaryColor
        instructions
      }
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($data: DeleteAccountInput) {
    deleteAccount(data: $data) {
      message
      success
    }
  }
`;
