import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($data: CancelSubscriptionInput) {
    cancelSubscription(data: $data) {
      message
      status
    }
  }
`;
