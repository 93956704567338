/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  TABLE: '/tables',
  SAMPLE: '/sample',
  QUIZ: '/quiz',
  QUIZ_DETAIL: '/quiz/:id',
  NOT_FOUND: '/404',
  SESSIONS: '/sessions',
  ADD_QUIZ: '/add',
  EDIT_QUIZ: '/edit/:id',
  DETAILS: '/details/:id',
  ONBOARDING: '/my-account',
  PROFILE: '/profile',
  SUBSCRIPTION: '/subscription',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  QUIZ: 'Quiz',
  SESSION: 'Sessions',
  SUBSCRIPTION: 'Subscription',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  OTP: /\b\d{5}\b/g,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;

export const ORDER = [
  { label: 'Newest', value: 'DESC' },
  { label: 'Oldest', value: 'ASC' },
];

export const SORT_ON = [
  { label: 'CreatedAt', value: 'createdAt' },
  { label: 'Title', value: 'title' },
];

export const CREDIT_TYPE = [
  { name: 'Cast', value: 'CAST' },
  { name: 'Crew', value: 'CREW' },
];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' },
];
export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const MOVIE_STATUS = {
  PENDING: 'Pending',
  RELEASED: 'Released',
};

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const DB = {
  USERS: 'users',
  MEMBER: 'member',
};

export const STORAGE = {
  PROFILE: 'profile',
  QUIZ: 'quiz',
};

export const DEFAULT_PAGINATION = {
  LIMIT: 5,
};

export const STATUS_LIST = {
  PUBLISHED: { name: 'Published', value: 'PUBLISHED' },
  NOT_PUBLISHED: { name: 'Unpublished', value: 'NOT_PUBLISHED' },
};

export const EXPIRY_LIST = [
  { name: 'Active', value: true },
  { name: 'Expired', value: false },
];

export const QUESTION_ID = [
  { name: 'A', value: 'A' },
  { name: 'B', value: 'B' },
  { name: 'C', value: 'C' },
  { name: 'D', value: 'D' },
];

export const QUESTION_INTERVAL_TIME = 5;

export const QUESTION_TYPE = [{ name: 'MCQ', value: 'MCQ' }];

export const QUIZ_LIST_MODULE = {
  DETAILS: 'Details',
  QUIZ: 'Quiz',
  SESSIONS: 'Sessions',
};

export const INSTRUCTION = `<ul>
    <li>
      Each correct answer is worth
      <span style="color: rgb(230, 0, 0);">(Specified) points.</span>
    </li>
    <li>
      For every correct answer, you get additional points for the time left on
      the timer E.g 7 seconds left on the timer =
      <span style="color: rgb(230, 0, 0);">7 additional points</span> (if the
      answer is correct).
    </li>
    <li><span style="color: rgb(230, 0, 0);">Top 3 </span> on the leaderboard get amazing prizes.</li>
  </ul>`;

export const MODE_OF_CONDUCT = {
  QUIZ: {
    label: 'Quiz',
    key: 'QUIZ',
  },
  EXAM: {
    label: 'Exam',
    key: 'EXAM',
  },
};

export const PRIMARY_COLOR = '#5636d3';

export const PLANS = {
  FREE: 'FREE',
  PROFESSIONAL: 'PROFESSIONAL',
  ENTERPRISE: 'ENTERPRISE',
};

export const PAYMENT_CHECKOUT = {
  CHECKOUT_CLOSED: 'checkout.closed',
  CHECKOUT_COMPLETED: 'checkout.completed',
  STATUS: {
    COMPLETED: 'completed',
    DRAFT: 'draft',
    // PENDING: 'PENDING', //kept for future use
    READY: 'ready',
  },
  KEY: 'LIFE_TIME_DEAL',
};
